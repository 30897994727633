import { HttpClient } from '@angular/common/http';
import { Injectable, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { UserAction, UserInfo } from '@longnecktech/splash-commons-fe';
import { UserActivityService } from '@services/user-activity.service';
import { HubGame } from '@shared/types/game';
import { LobbyGameType } from '@shared/types/game-group';
import { Theme, ConfigValue } from '@shared/types/theme';
import {
  BehaviorSubject,
  map,
  interval,
  filter,
  switchMap,
  Observable,
  mergeMap,
  catchError,
  of,
} from 'rxjs';
import { UserActionService } from './user-action.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private _isMobile = new BehaviorSubject<boolean>(false);
  private _user = new BehaviorSubject<UserInfo | undefined>(undefined);
  private _hub = new BehaviorSubject<HubGame | undefined>(undefined);
  private _theme = new BehaviorSubject<Theme | undefined>(undefined);

  thirdPartyToken = '';
  gameUuid?: string;
  instance?: string;
  readonly user$ = this._user.asObservable();
  readonly isMobile$ = this._isMobile.asObservable();
  readonly hub$ = this._hub.asObservable();
  // don't display the casino tab if neither spinmachine or wheel are enabled
  readonly isCasinoEnabled$ = this.hub$.pipe(
    map(
      (hub) =>
        hub?.activeGames?.includes(LobbyGameType.SPIN_MACHINE) ||
        hub?.activeGames?.includes(LobbyGameType.WHEEL),
    ),
  );
  readonly campaign$ = this.hub$.pipe(map((hub) => hub?.campaign));

  private destroyRef = inject(DestroyRef);

  constructor(
    private http: HttpClient,
    private userActivityService: UserActivityService,
    private router: Router,
    private userActionService: UserActionService,
  ) {}

  setUser(userInfo: UserInfo): void {
    this._user.next(userInfo);
  }

  setIsMobile(isMobile: boolean): void {
    this._isMobile.next(isMobile);
  }

  setCurrentGame(game: HubGame): void {
    this._theme.next(game.theme);
    this._hub.next(game);
  }

  getThemeLabels(): ConfigValue[] | undefined {
    return this._theme.value?.labels;
  }

  getUserLanguage(): string | undefined {
    return this._user.value?.language;
  }

  updateAuthToken(): void {
    // Run every 10 minutes
    interval(10 * 60 * 1000)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        // this interval must not refresh the token trigger if the user is not active
        filter(() => !this.userActivityService.isUserInactive()),
        switchMap(() => this.fetchToken()),
      )
      .subscribe({
        next: (user) => {
          this.setUser(user);
        },
        error: () => {
          this.router.navigate(['/error']);
        },
      });
  }

  fetchToken(): Observable<UserInfo> {
    return this.http
      .post<UserInfo>(`${environment.backendUrl}/api/public/auth/token`, {
        token: this.thirdPartyToken,
        instance: this.instance,
      })
      .pipe(
        mergeMap((user) =>
          this.getIp().pipe(
            mergeMap((res) =>
              this.userActionService
                .sendAction(
                  UserAction.GAME_LOGIN,
                  { ip: res.ip },
                  this._hub.value?.uuid || '',
                )
                .pipe(map(() => user)),
            ),
            catchError((err) => {
              console.error('Failed to track GAME_LOGIN:', err);
              return of(user);
            }),
          ),
        ),
        catchError((err) => {
          console.error('Error fetching token:', err);
          throw err;
        }),
      );
  }

  getIp(): Observable<{ ip: string }> {
    return this.http.get<{ ip: string }>(
      environment.backendUrl + '/api/user/get-ip',
    );
  }
}
