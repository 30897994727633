import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { UserAction } from '@longnecktech/splash-commons-fe';

@Injectable({
  providedIn: 'root',
})
export class UserActionService {
  constructor(private http: HttpClient) {}

  sendAction(
    action: UserAction,
    metadata: unknown,
    gameUuid: string,
  ): Observable<void> {
    return this.http.post<void>(environment.backendUrl + '/api/user/action', {
      action,
      metadata,
      gameUuid,
    });
  }
}
